gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);

// function initPage() {

//     document.querySelectorAll('a').forEach(link => {
//         link.addEventListener('click', (event) => {
//             event.preventDefault();
//             const href = link.getAttribute('href');
//             barba.go(href);
//         });
//     });

// }

document.addEventListener('DOMContentLoaded', function () {

    // initPage();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    /*-------------------------------------------------------------------------------
    ## c-hero
    -------------------------------------------------------------------------------*/
    // function herosAnim(barba) {

    //     const reveal2 = gsap.utils.toArray(".c-hero");
    //     reveal2.forEach((element, index) => {
    //         let layer = element.querySelector(".c-hero__layer");
    //         let curtain = element.querySelector(".c-hero__curtain");
    //         let img = element.querySelector(".c-hero__img2");
    //         let text = element.querySelectorAll(".anim-txt");
    //         let btn = element.querySelector(".btn-link");
    //         let icon = element.querySelector(".js-c-hero__icon");
    //         let content = element.querySelector(".c-hero__content");
    //         let triangle = element.querySelector(".c-hero .cont-triangle .triangle");
    //         let triangleCont = element.querySelector(".c-hero .cont-triangle-cont");
    //         let anim = gsap.timeline();
    //         gsap.set(btn, { yPercent: 100 })
    //         if (barba !== true) {

    //             anim
    //                 .fromTo(curtain, { right: '-100%' }, { right: 0, ease: "expo.inOut", duration: 1.1 })
    //                 .to(img, { opacity: 1, duration: 0.3 }, 1.1)
    //                 .to(curtain, { right: "100%", ease: "expo.inOut", duration: 1.1 })
    //         }

    //         if (element.classList.contains('hero-about') || element.classList.contains('hero-project')) {

    //             anim.fromTo(triangle, { yPercent: 100, transformOrigin: 'bottom' }, { yPercent: 0, ease: "expo.inOut", duration: 1.1 }, '-=0.7')
    //             // anim.fromTo(triangle, {clipPath:'inset(100% 0% 0% 0%)', y:0, transformOrigin: 'bottom'}, {clipPath:'inset(0% 0% 0% 0%)', ease: "expo.inOut", duration: 1.1}, '-=0.7')
    //             // anim.fromTo(triangle, {clipPath:'inset(100% 0% 0% 0%)', y:0, transformOrigin: 'bottom'}, {clipPath:'inset(0% 0% 0% 0%)', ease: "expo.inOut", duration: 1.1}, '-=0.7')
    //             anim.fromTo(triangleCont, { y: -10, autoAlpha: 0, }, { y: 0, autoAlpha: 1, ease: "expo.inOut", duration: 0.5 }, '-=0.')
    //         }
    //         else {
    //             anim.fromTo(triangle, { yPercent: -200, transformOrigin: 'bottom' }, { yPercent: -100, ease: "expo.inOut", duration: 1.1 }, '-=0.7')
    //             // anim.fromTo(triangle, {clipPath:'inset(0% 0% 100% 0%)', y:0, transformOrigin: 'bottom'}, {clipPath:'inset(0% 0% 0% 0%)', ease: "expo.inOut", duration: 1.1}, '-=0.7')
    //             anim.fromTo(triangleCont, { y: 10, autoAlpha: 0, }, { y: 0, autoAlpha: 1, ease: "expo.inOut", duration: 0.5 }, '-=0.')
    //         }
    //         if (window.innerWidth < 740) {
    //             anim.fromTo(content, { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, ease: "expo.inOut", duration: 1.1 }, '-=0.3')
    //         } else {
    //             anim.fromTo(layer, { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, ease: "expo.inOut", duration: 1.1 }, '-=0.3')
    //         }
    //         if (icon) {
    //             anim.fromTo(icon, { yPercent: 100 }, { yPercent: 0, duration: 0.5 })
    //         }
    //         // anim.fromTo(text, { yPercent: 100 }, { yPercent: 0, duration: 0.5, stagger: 0.2 })
    //         let split = new SplitText(text, { type: "lines" });
    //         // console.log(split.lines, text.offsetHeight)
    //         anim.fromTo(split.lines, {
    //             yPercent: 500,
    //         }, {
    //             yPercent: 0,
    //             linesClass: "split-line",
    //             ease: "circ.out",
    //             stagger: 0.3,
    //             duration: 0.8
    //         })
    //         if (btn) {
    //             anim.fromTo(btn, { yPercent: 100 }, { yPercent: 0, duration: 0.5 })
    //         }
    //         ScrollTrigger.create({
    //             trigger: element,
    //             start: "top 60%",
    //             // markers: true,
    //             toggleActions: "play none none none",
    //             animation: anim
    //         });
    //     });
    // }
    // herosAnim();

    /*-------------------------------------------------------------------------------
    ## barba.js
    -------------------------------------------------------------------------------*/
    // barba.init({
    //     depuración: true,
    //     sync: true,
    //     transitions: [{
    //         // name: 'custom-transition',
    //         from: {
    //             namespace: ['proyectos']
    //         },
    //         // to: {
    //         //     namespace: ['single-proyecto']
    //         // },
    //         async leave(data) {
    //             const change = gsap.timeline();
    //             const done = this.async();
    //             gsap.set(data.trigger, {
    //                 opacity: 1,
    //                 position: 'fixed',
    //                 maxWidth: 'inherit',
    //                 maxHeight: 'inherit',
    //                 backgroundColor: "white",
    //             });
    //             gsap.set(data.trigger.querySelector('.c-post__content'), {
    //                 opacity: 0,

    //             });

    //             change.to('.c-header', {
    //                 yPercent: -100, duration: 1, ease: "power4.out"
    //             })
    //             change.to('.c-header-alt', {
    //                 yPercent: -100, duration: 1, ease: "power4.out"
    //             }, '<')
    //                 .to(data.trigger, {
    //                     width: '100vw',
    //                     height: '100%',
    //                     top: '50%',
    //                     left: '50%',
    //                     yPercent: -50,
    //                     xPercent: -50,

    //                     zIndex: 99

    //                 }, '<')
    //                 .to(data.trigger.querySelector('.c-post__figure'), {
    //                     width: '95vw',
    //                     height: '80vh',
    //                     top: "101.5px",
    //                     left: '50%',
    //                     xPercent: -50,
    //                     duration: 1, ease: "power4.out"
    //                 }, '<')
    //             setTimeout(() => {
    //                 done();
    //             }, 1000);
    //             // done();

    //         },
    //         async enter(data) {
    //             const change = gsap.timeline();
    //             const done = this.async();

    //             gsap.set(data.next.container.querySelector('.c-hero__curtain'), { right: "100%" })
    //             gsap.set(data.next.container.querySelector('.c-hero__img2'), { opacity: 1 })
    //             gsap.set(document.querySelector('.c-heading'), { paddingTop: '3000%' })
    //             gsap.set(data.trigger.querySelector('.c-post__figure img'), { bottom: 0, objectPosition: "bottom", width: '99%' })

    //             change.to(data.trigger, {
    //                 right: 0,
    //                 left: "5.1%",
    //                 top: "101.5px",
    //                 backgroundColor: "transparent",
    //                 // zIndex: 99
    //                 yPercent: 0,
    //                 xPercent: 0,
    //                 // width: "auto",
    //                 duration: 1, ease: "power4.out"
    //             })

    //             change.to(data.trigger.querySelector('.c-post__figure'), {
    //                 width: '95vw',
    //                 height: '80vh',
    //                 top: 0,
    //                 right: 0,
    //                 left: 0,
    //                 yPercent: 0,
    //                 xPercent: 0,
    //                 duration: 1, ease: "power4.out"
    //             }, '<')
    //             setTimeout(() => {
    //                 herosAnim(true)
    //                 done();
    //             }, 1000);
    //         }
    //     }]
    // });



    /*-------------------------------------------------------------------------------
    ## data-anim 
    -------------------------------------------------------------------------------*/

    let selector = document.querySelectorAll('[data-anim]');

    selector.forEach((element, index) => {

        let animName = element.getAttribute("data-anim")

        switch (animName) {
            case "fade-in-up-text":
                // let split = new SplitText(
                //     element.querySelectorAll('.anim-text'), { type: "lines" }
                // );

                // let fadeInUpText = gsap.timeline();
                // fadeInUpText.fromTo(split.lines, {
                //     yPercent: 300,
                // }, {
                //     yPercent: 0,
                //     linesClass: "split-line",
                //     ease: "circ.out",
                //     stagger: 0.3,
                //     duration: 1.5,
                // })

                // ScrollTrigger.create({
                //     trigger: element,
                //     start: "top 80%",
                //     toggleActions: "play none none none",
                //     animation: fadeInUpText,
                //     // markers: true,
                //     delay: 1
                // });

                let split = new SplitText(
                    element.querySelectorAll('.js-split'), { type: "lines" }
                );

                split.lines.forEach(line => {
                    const wrapper = document.createElement('span');
                    wrapper.classList.add('line-wrapper');
                    line.parentNode.insertBefore(wrapper, line);
                    wrapper.appendChild(line);
                });

                let splitText = gsap.from(split.lines, {
                    yPercent: 300,
                    ease: "circ.out",
                    stagger: 0.3,
                    duration: 1.3,
                    autoAlpha: 0
                }, "<")

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    // markers: true,
                    toggleActions: "play none none none",
                    animation: splitText
                });

                break;
            case "fade-in-up":

                let fadeInUp = gsap.from(element, {
                    autoAlpha: 0,
                    ease: "power4.inOut",
                    duration: 2,
                    y: 20
                });

                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: fadeInUp,
                    // markers: true
                });

                break;
            case "to-up":
                // console.log('to-up')
                let goUp = gsap.timeline();
                // console.log('este', element.querySelector('.curtain'))
                gsap.set(element.children[1], { top: '100%', autoAlpha: 0 })
                goUp
                    .fromTo(element.querySelector('.curtain'), { bottom: '-100%' }, { bottom: 0, ease: "expo.inOut", duration: 1.1 })
                    .fromTo(element.querySelector('img'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3 })
                    .to(element.querySelector('.curtain'), { bottom: "100%", ease: "expo.inOut", duration: 1.1 })
                if (element.children[1]) {
                    goUp.to(element.children[1], { top: "0", autoAlpha: 1, ease: "expo.inOut", duration: 1.1 }, '<')
                }
                ScrollTrigger.create({
                    trigger: element,
                    start: "top 80%",
                    toggleActions: "play none none none",
                    animation: goUp,
                    // markers: true
                });

                break;
            default:

                // no animation F

                break;
        }

    });


});

/*-------------------------------------------------------------------------------
## open/close menu
-------------------------------------------------------------------------------*/

function menuToggle() {

    const menuAnimation = new gsap.timeline({
        ease: "power4.inOut"
    });

    menuAnimation
        // .to(bodyElement, { overflow: 'hidden' })
        .to('#main', { autoAlpha: 0 }, '<')
        .to('.c-header', { autoAlpha: 0 }, '<')
        .to('.c-header-alt', { autoAlpha: 0 }, '<')
        .to('.c-header__dropdown', { autoAlpha: 1 }, '<')
        .fromTo('.c-header__wrapper-overlay .c-header__overlay', { height: 0 }, { height: '100%', stagger: 0.2 }, '<')
        .to('.c-header__content .row', { autoAlpha: 1, stagger: 0.2 })

    menuAnimation.pause();

    const openMenu = document.getElementById('js-open-menu');
    const closeMenu = document.getElementById('js-close-menu');

    openMenu.addEventListener('click', function () {

        document.body.classList.add('overflow-hidden')
        menuAnimation.play();
        document.querySelector('.c-header__dropdown .container').style.overflow = 'auto'
    });

    closeMenu.addEventListener('click', function () {
        document.querySelector('.c-header__dropdown .container').style.overflow = 'hidden';
        menuAnimation.reverse().then(() => {
            document.querySelector('.c-header__dropdown .container').style.overflow = '';
            document.body.classList.remove('overflow-hidden');
        });
    });

}

/*-------------------------------------------------------------------------------
## swiper gallery (custom cursor)
-------------------------------------------------------------------------------*/

function swiperCursor() {

    let touch = false;

    if (document.querySelector('html').classList.contains('touchevents')) {
        touch = true
    }

    let swiperParent1 = document.querySelectorAll('.parent1');

    if (swiperParent1) {
        swiperParent1.forEach(swiper => {
            let mySwiper = new Swiper(swiper, {
                loop: true,
                allowTouchMove: false,
                effect: 'creative',
                creativeEffect: {
                    prev: {
                        shadow: true,
                        translate: [0, 0, -1],
                    },
                    next: {
                        translate: ["100%", 0, 0],
                    },
                },
                pagination: {
                    el: swiper.querySelector('.swiper-pagination-bullets'),
                    type: 'bullets',
                    clickable: true,
                },
                navigation: {
                    nextEl: swiper.querySelector('.c-carousel__button-next'),
                    prevEl: swiper.querySelector('.c-carousel__button-prev'),
                },
                on: {
                    slideChange: function () {
                        let current = mySwiper.realIndex + 1;
                        let total = mySwiper.slides.length; // because of loop
                        swiper.querySelector('.swiper-pagination-fraction').innerHTML = `${current} / ${total}`;
                    },
                },
            });

            // Set initial fraction pagination
            let initialCurrent = mySwiper.realIndex + 1;
            let initialTotal = mySwiper.slides.length;
            swiper.querySelector('.swiper-pagination-fraction').innerHTML = `${initialCurrent} / ${initialTotal}`;

            const follower = swiper.querySelector('.follower');

            if (!touch) {
                gsap.set(follower, {
                    xPercent: -50,
                    yPercent: -50,
                });

                init(swiper);
            }
        });
    }



    function onMouseEnter(el) {
        gsap.to(el.querySelector('.follower'), 0.3, {
            autoAlpha: 1,
            scale: 1,
        })
    }

    function onMouseMove(el, ev) {
        const mitadAnchoDiv = el.offsetWidth / 2;
        const posicionXCursor = ev.clientX - el.getBoundingClientRect().left;

        if (posicionXCursor < mitadAnchoDiv) {
            gsap.to(el.querySelector('.follower'), 0.3, {
                rotation: 0
            });
        } else {
            gsap.to(el.querySelector('.follower'), 0.3, {
                rotation: 180
            });
        }

        gsap.to(el.querySelector('.follower'), 0, {
            x: ev.offsetX,
            y: ev.offsetY,
        })
    }

    function onMouseLeave(el) {

        gsap.to(el.querySelector('.follower'), 0.3, {
            autoAlpha: 0, scale: 0.1
        })
    }

    function onMouseClick(el, ev) {
        const mitadAnchoDiv = el.offsetWidth / 2;
        const posicionXCursor = ev.clientX - el.getBoundingClientRect().left;
        if (posicionXCursor < mitadAnchoDiv) {
            el.querySelector('.c-carousel__button-prev').click();
        } else {
            el.querySelector('.c-carousel__button-next').click();
        }
    }

    function init(el) {


        el.addEventListener('click', (ev) => {
            onMouseClick(el, ev)
        })
        el.addEventListener('mousemove', (ev) => {
            onMouseMove(el, ev)
        })
        el.addEventListener('mouseleave', () => {
            onMouseLeave(el)
        })
        el.addEventListener('mouseenter', () => {
            onMouseEnter(el)
        })

    };
}

/*-------------------------------------------------------------------------------
## c-hero (HomePage, Product-view)
-------------------------------------------------------------------------------*/

function heroHome() {

    const heroItem = gsap.utils.toArray(".c-hero");

    heroItem.forEach((element, index) => {

        const figure = element.querySelector(".c-hero__figure");
        const image = element.querySelector(".c-hero__image");
        const inner = element.querySelector(".c-hero__inner");
        const icon = element.querySelector(".c-hero__icon");
        const triangle = element.querySelector(".c-hero__triangle");

        let split = new SplitText(
            element.querySelectorAll('.js-split'), { type: "lines" }
        );

        split.lines.forEach(line => {
            const wrapper = document.createElement('span');
            wrapper.classList.add('line-wrapper');
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        let timeline = gsap.timeline({
            defaults: {
                duration: 1.4,
                ease: "circ.out",
            }
        });

        timeline.to([figure, image, inner], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            stagger: 0.2
        })

        timeline.from(split.lines, {
            yPercent: 200,
            stagger: 0.3,
        })

        timeline.fromTo(icon, {
            y: 200
        }, {
            autoAlpha: 1,
            y: 0
        }, "<")

        timeline.to(triangle, {
            y: 0,
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-hero-about
-------------------------------------------------------------------------------*/

function heroAbout() {

    const heroItem = gsap.utils.toArray(".c-hero-about");

    heroItem.forEach((element, index) => {

        const figure = element.querySelector(".c-hero-about__figure");
        const image = element.querySelector(".c-hero-about__image");
        const inner = element.querySelector(".c-hero-about__inner");
        const icon = element.querySelector(".c-hero-about__icon");
        const triangle = element.querySelector(".c-hero-about__triangle");

        let split = new SplitText(
            element.querySelectorAll('.js-split'), { type: "lines" }
        );

        split.lines.forEach(line => {
            const wrapper = document.createElement('span');
            wrapper.classList.add('line-wrapper');
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        let timeline = gsap.timeline({
            defaults: {
                duration: 1.4,
                ease: "circ.out",
            }
        });

        timeline.to([figure, image, inner], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            stagger: 0.2
        })

        timeline.from(split.lines, {
            yPercent: 200,
            stagger: 0.3,
        })

        timeline.fromTo(icon, {
            y: 200
        }, {
            autoAlpha: 1,
            y: 0
        }, "<")

        timeline.to(triangle, {
            y: 0,
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-hero-project
-------------------------------------------------------------------------------*/

function heroProject() {

    const heroItem = gsap.utils.toArray(".c-hero-project");

    heroItem.forEach((element, index) => {

        const figure = element.querySelector(".c-hero-project__figure");
        const image = element.querySelector(".c-hero-project__image");
        const inner = element.querySelector(".c-hero-project__inner");
        const triangle = element.querySelector(".c-hero-project__triangle");

        let split = new SplitText(
            element.querySelectorAll('.js-split'), { type: "lines" }
        );

        split.lines.forEach(line => {
            const wrapper = document.createElement('span');
            wrapper.classList.add('line-wrapper');
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        let timeline = gsap.timeline({
            defaults: {
                duration: 1.4,
                ease: "circ.out",
            }
        });

        timeline.to([figure, image, inner], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            stagger: 0.2
        })

        timeline.from(split.lines, {
            yPercent: 200,
            stagger: 0.3,
        })

        timeline.to(triangle, {
            y: 0,
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## lightGallery 
-------------------------------------------------------------------------------*/

function initGallery() {
    const gallery = document.querySelectorAll(".js-gallery");

    if (gallery.length === 0) {
        return;
    }

    gallery.forEach((element, index) => {
        lightGallery(element, {
            controls: true,
            counter: true,
            plugins: [lgAutoplay, lgFullscreen, lgThumbnail, lgZoom]
        });
    });
}

/*-------------------------------------------------------------------------------
## hide header on scroll
-------------------------------------------------------------------------------*/

function handleScroll() {

    gsap.defaults({
        ease: "power1.out",
        duration: 0.6
    });

    const headerClass = ".js-header";
    const hiddenPosition = "-101%";
    const visiblePosition = "0";
    const topDisplay = 100;

    let lastScrollTop = 0;

    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", function () {
        let scrollTop = window.scrollY;

        if (scrollTop > topDisplay) {
            let direction =
                scrollTop > lastScrollTop ? hiddenPosition : visiblePosition;
            gsap.to(headerClass, { y: direction });
        }

        lastScrollTop = scrollTop;
    });
}

/*-------------------------------------------------------------------------------
## flag animation
-------------------------------------------------------------------------------*/

function flagAnimation() {

    gsap.defaults({
        ease: "expo.out",
        duration: 1.2
    });

    const images = gsap.utils.toArray(".c-flag");

    if (images.length === 0) {
        return;
    }

    images.forEach((element) => {

        const triangle = element.querySelector(".c-flag__triangle");
        const inner = element.querySelector(".c-flag__inner");

        const timeline = gsap.timeline();

        timeline
            .to(triangle, { top: 0 })
            .from(inner, { autoAlpha: 0 })

        ScrollTrigger.create({
            trigger: element,
            start: "top 60%",
            toggleActions: "play none none none",
            animation: timeline,
            // markers: true // Descomentar para ver los marcadores de ScrollTrigger
        });
    });
}

/*-------------------------------------------------------------------------------
## c-blog
-------------------------------------------------------------------------------*/

function blogPost() {

    const blogPost = gsap.utils.toArray(".c-blog");

    blogPost.forEach((element, index) => {

        postFigure = element.querySelector('.c-blog__figure')
        postImage = element.querySelector('.c-blog__img')
        postContent = element.querySelector('.c-blog__content')

        let timeline = gsap.timeline();

        timeline.from(postFigure, {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "circ.out",
        })

        timeline.from(postImage, {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "circ.out",
        }, .8)

        timeline.from(postContent, {
            autoAlpha: 0,
            ease: "circ.out",
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-qw
-------------------------------------------------------------------------------*/

function postQw() {

    const qwPost = gsap.utils.toArray(".qw__wrapper");

    qwPost.forEach((element, index) => {

        figure = element.querySelector('.qw__figure')
        image = element.querySelector('.qw__img')
        qwContent = element.querySelector('.qw__con')

        let timeline = gsap.timeline();

        timeline.from(figure, {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "circ.out",
        })

        timeline.from(image, {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "circ.out",
        }, .8)

        timeline.to(qwContent, {
            autoAlpha: 1,
            ease: "circ.out",
        })

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-banner
-------------------------------------------------------------------------------*/

function banner() {

    const target = gsap.utils.toArray(".c-banner");

    target.forEach((element, index) => {

        const wrapper = element.querySelector(".c-banner__wrapper");
        const overlay = element.querySelector(".c-banner__overlay");
        const media = element.querySelector(".c-banner__media");
        const square = element.querySelector(".c-banner__square");

        let split = new SplitText(
            element.querySelectorAll('.js-split'), { type: "lines" }
        );

        split.lines.forEach(line => {
            const wrapper = document.createElement('span');
            wrapper.classList.add('line-wrapper');
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        let timeline = gsap.timeline({
            defaults: {
                duration: 1.4,
                ease: "circ.out",
            }
        });

        timeline.to([wrapper, overlay], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        })

        timeline.to([media, square], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            stagger: 0.2
        }, "0.2")

        timeline.from(split.lines, {
            yPercent: 300,
            stagger: 0.3,
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## banner project
-------------------------------------------------------------------------------*/

function bannerProject() {

    const target = gsap.utils.toArray(".c-banner-project");

    target.forEach((element, index) => {

        const figure = element.querySelector(".c-banner-project__figure");
        const image = element.querySelector(".c-banner-project__image");
        const inner = element.querySelector(".c-banner-project__inner");
        const triangle = element.querySelector(".c-banner-project__triangle");

        let split = new SplitText(
            element.querySelectorAll('.js-split'), { type: "lines" }
        );

        split.lines.forEach(line => {
            const wrapper = document.createElement('span');
            wrapper.classList.add('line-wrapper');
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        let timeline = gsap.timeline({
            defaults: {
                duration: 1.4,
                ease: "circ.out",
            }
        });

        timeline.to([figure, image, inner], {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            stagger: 0.2
        })

        timeline.from(split.lines, {
            yPercent: 300,
            stagger: 0.3,
        }, "<")

        timeline.to(triangle, {
            y: 0,
        }, "<")

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-image
-------------------------------------------------------------------------------*/

function imagePath() {

    const target = gsap.utils.toArray(".c-image");

    target.forEach((element, index) => {

        const figure = element.querySelector(".c-image__figure");
        const image = element.querySelector(".c-image__image");
        const dot = element.querySelectorAll(".c-image__dot");

        let timeline = gsap.timeline();

        timeline.from([figure, image], {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "expo.out",
            stagger: 0.6
        })

        if (dot.length > 0) {
            timeline.to(dot, {
                autoAlpha: 1,
                stagger: 0.6
            });
        }

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-carousel
-------------------------------------------------------------------------------*/

function carouselPath() {

    const target = gsap.utils.toArray(".c-carousel");

    target.forEach((element, index) => {

        const figure = element.querySelector(".c-carousel__wrapper");
        const image = element.querySelector(".c-carousel__swiper");

        let timeline = gsap.timeline();

        timeline.from([figure, image], {
            duration: 1.5,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: "expo.out",
            stagger: 0.6
        })

        ScrollTrigger.create({
            trigger: element,
            start: "top center",
            // markers: true,
            toggleActions: "play none none none",
            animation: timeline
        });
    });
}

/*-------------------------------------------------------------------------------
## c-filter (swiper)
-------------------------------------------------------------------------------*/

function swiperFilter() {

    var swiper = new Swiper(".c-filter__swiper", {
        slidesPerView: "auto",
        freeMode: true,
    });
}

/*-------------------------------------------------------------------------------
## c-news
-------------------------------------------------------------------------------*/

const newGallery = () => {

    const newListItems = document.querySelectorAll('.new-list li');
    const newImages = document.querySelectorAll('.new-image');

    newListItems.forEach(item => {

        item.addEventListener('mouseenter', () => {
            const newId = item.getAttribute('data-new');

            newImages.forEach(img => {
                img.classList.remove('active');
                if (img.id === newId) {
                    img.classList.add('active');
                }
            });
        });
    });

}

/*-------------------------------------------------------------------------------
## scroll-to
-------------------------------------------------------------------------------*/

function scrollTo() {

    const target = document.querySelectorAll(".c-scrollspy__nav .c-scrollspy__nav-item .c-scrollspy__nav-link");

    // target.forEach((btn, index) => {
    //     btn.addEventListener("click", () => {

    //         gsap.to(window, { duration: 1, scrollTo: { y: ".section" + (index + 1), offsetY: 70 } });
    //     });
    // });
}

function initSwiperSpecs() {
    const swiperContainers = document.querySelectorAll(".myspecs");
    let count = 0;

    swiperContainers.forEach(function (container) {
        if (container) {
            count++;
            container.classList.add("swiper-container-" + count);
            let swiperInstance = new Swiper(".swiper-container-" + count, {
                slidesPerView: "auto",
                spaceBetween: 8,
            });
        } else {
            console.error("swiper container not found.");
        }
    });
}

// function swiperPanel2() {
//     // Inicializamos el Swiper de paneles (miniaturas).
//     var swiperpanel = new Swiper(".mySwiper22", {
//         slidesPerView: "auto",
//         freeMode: true,
//         watchSlidesProgress: true,
//     });

//     // Inicializamos el Swiper principal (contenido).
//     var swiper2 = new Swiper(".mySwiper33", {
//         thumbs: {
//             swiper: swiperpanel,
//         },
//         on: {
//             // Animación de salida del slide actual
//             slideChangeTransitionStart: function () {
//                 const currentSlide = swiper2.slides[swiper2.activeIndex];

//                 // Selección de los elementos dentro del slide actual
//                 const contentMainFigure = currentSlide.querySelector('.c-swiper-panel__content-main-figure');
//                 const contentMainImg = currentSlide.querySelector('.c-swiper-panel__content-main-img');

//                 let timeline = gsap.timeline();

//                 // Animación de salida (de izquierda a derecha)
//                 if (contentMainFigure && contentMainImg) {

//                     gsap.set([contentMainFigure, contentMainImg], {
//                         clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
//                     });

//                     timeline.to([contentMainFigure, contentMainImg], {
//                         duration: 1.5,
//                         clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)', // De izquierda a derecha
//                         ease: "expo.inOut",
//                         stagger: 0.3
//                     });
//                 }
//             },

//             // Animación de entrada del nuevo slide con un retraso para suavizar la transición
//             slideChangeTransitionEnd: function () {
//                 const currentSlide = swiper2.slides[swiper2.activeIndex];

//                 // Selección de los elementos dentro del nuevo slide
//                 const contentMainFigure = currentSlide.querySelector('.c-swiper-panel__content-main-figure');
//                 const contentMainImg = currentSlide.querySelector('.c-swiper-panel__content-main-img');

//                 let timeline = gsap.timeline();

//                 // Agregamos un retraso para que la entrada no se solape con la salida
//                 setTimeout(function () {
//                     // Configuración inicial del clipPath para la animación de entrada (desde la derecha)
//                     if (contentMainFigure && contentMainImg) {

//                         gsap.set([contentMainFigure, contentMainImg], {
//                             clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
//                         });

//                         // Animación de entrada (de derecha a izquierda)
//                         timeline.to([contentMainFigure, contentMainImg], {
//                             duration: 1.5,
//                             clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // Llega de derecha a izquierda
//                             ease: "expo.inOut",
//                             stagger: 0.3
//                         });
//                     }
//                 }, 500); // Retraso de 500ms para suavizar la transición
//             }
//         }
//     });
// }


document.addEventListener("DOMContentLoaded", function () {

    menuToggle();
    swiperCursor();
    heroHome();
    heroAbout();
    heroProject();
    // initGallery();
    handleScroll();
    flagAnimation();
    banner();
    bannerProject();
    blogPost();
    postQw();
    imagePath();
    carouselPath();
    swiperFilter();
    setTimeout(newGallery, 1000);
    // scrollTo();
    initSwiperSpecs();
    // swiperPanel2();

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })


    // start

    function ParallaxSwiper(element, params) {
        this.config = Object.assign(
            {
                initialSlide: 0,
                prevIndex: 0,
                nextIndex: 0,
                activeIndex: 0,
                target: "",
                speed: 2,
                touch: false,
                duration: 5000,
                autoplay: false,
                listen: null,
                callback: null,
            },
            params
        );

        const parallaxElement = $(`${element}`);
        const parallaxElementChildren = parallaxElement.children();
        const max = parallaxElementChildren.length - 1;

        this.activeIndex = 0;
        this.prevIndex = max;
        this.nextIndex = this.config.initialSlide + 1 > max ? 0 : this.config.initialSlide + 1;
        this.animateLock = true;
        this.autoplayTimer = null;
        this.scrollTimeout = null;

        this.init = () => {
            this.setCurrent(this.config.initialSlide);

            if (this.config.touch) {
                let moveX = 0;
                let differX = 0;

                const resetMove = () => {
                    parallaxElement.off('mousemove touchmove', mouseMoveX);
                    parallaxElement.off('mouseup touchend', resetMove);

                    if (moveX > differX) {
                        this.slideToNext();
                    } else {
                        this.slideToPrev();
                    }
                    moveX = 0;
                    differX = 0;
                };

                const mouseMoveX = (event) => {
                    differX = event.clientX || event.touches[0].clientX;
                };

                parallaxElement.on('mousedown touchstart', (event) => {
                    event.preventDefault();
                    moveX = event.clientX || event.touches[0].clientX;
                    parallaxElement.on('mousemove touchmove', mouseMoveX);
                    parallaxElement.on('mouseup touchend', resetMove);
                });
            }

            // Añadir evento de scroll
            parallaxElement.on('wheel', (event) => {
                event.preventDefault();
                if (this.animateLock) {
                    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

                    this.scrollTimeout = setTimeout(() => {
                        if (event.originalEvent.deltaY > 0) {
                            this.slideToNext(); // Scroll hacia abajo
                        } else {
                            this.slideToPrev(); // Scroll hacia arriba
                        }
                    }, 100); // Timeout para evitar demasiados disparos rápidos del evento
                }
            });
        };

        this.setCurrent = (index) => {
            gsap.set(parallaxElementChildren, { xPercent: 100 });
            gsap.set(parallaxElementChildren.find(this.config.target), { xPercent: 50 });
            gsap.set(parallaxElementChildren.eq(index), { xPercent: 0 });
            gsap.set(parallaxElementChildren.eq(index).find(this.config.target), {
                xPercent: 0,
            });

            this.activeIndex = index;
            this.prevIndex = index - 1 === 0 ? max : index - 1;
            this.nextIndex = index + 1 > max ? 0 : index + 1;

            this.listen();
        };

        this.listen = () => {
            if (typeof this.config.listen === 'function') {
                this.config.listen({
                    prevIndex: this.prevIndex,
                    nextIndex: this.nextIndex,
                    activeIndex: this.activeIndex,
                    animateLock: this.animateLock,
                });
            }
        }

        this.slideToPrev = (index) => {
            this.listen();

            if (!this.animateLock) {
                return;
            }

            if (typeof index !== 'undefined') {
                this.prevIndex = index;
            }

            gsap.fromTo(
                parallaxElementChildren.eq(this.prevIndex),
                { xPercent: -100 },
                { xPercent: 0, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.prevIndex).find(this.config.target),
                { xPercent: 50 },
                { xPercent: 0, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.activeIndex),
                { xPercent: 0 },
                { xPercent: 100, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.activeIndex).find(this.config.target),
                { xPercent: 0 },
                {
                    xPercent: -50,
                    duration: this.config.speed,
                    onComplete: () => {
                        this.animateLock = true;
                        if (typeof this.config.callback === 'function') {
                            this.config.callback({
                                prevIndex: this.prevIndex,
                                nextIndex: this.nextIndex,
                                activeIndex: this.activeIndex,
                                animateLock: this.animateLock,
                            });
                        }
                    },
                }
            );

            this.activeIndex = this.prevIndex;
            if (this.prevIndex === 0) {
                this.prevIndex = max;
            } else {
                this.prevIndex--;
            }

            this.listen();

            this.animateLock = false;
        };

        this.slideToNext = (index) => {
            this.listen();

            if (!this.animateLock) {
                return;
            }

            if (index === this.activeIndex) {
                return;
            }

            if (index < this.activeIndex) {
                this.slideToPrev(index);
                return;
            }

            this.prevIndex = this.activeIndex;
            if (index) {
                this.activeIndex = index;
            } else {
                if (this.activeIndex < max) {
                    this.activeIndex++;
                } else {
                    this.activeIndex = 0;
                }
            }
            this.nextIndex = this.activeIndex + 1;
            if (this.nextIndex > max) {
                this.nextIndex = 0;
            }

            gsap.fromTo(
                parallaxElementChildren.eq(this.prevIndex),
                { xPercent: 0 },
                { xPercent: -100, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.prevIndex).find(this.config.target),
                { xPercent: 0 },
                { xPercent: 50, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.activeIndex),
                { xPercent: 100 },
                { xPercent: 0, duration: this.config.speed }
            );
            gsap.fromTo(
                parallaxElementChildren.eq(this.activeIndex).find(this.config.target),
                { xPercent: -50 },
                {
                    xPercent: 0,
                    duration: this.config.speed,
                    onComplete: () => {
                        this.animateLock = true;
                        if (typeof this.config.callback === 'function') {
                            this.config.callback({
                                prevIndex: this.prevIndex,
                                nextIndex: this.nextIndex,
                                activeIndex: this.activeIndex,
                                animateLock: this.animateLock,
                            });
                        }
                    },
                },
            );

            this.listen();

            this.animateLock = false;
        };

        this.slideTo = (index) => {
            if (index < this.activeIndex) {
                this.slideToPrev(index);
            } else {
                this.slideToNext(index);
            }
        }

        this.init();
    }

    const parallax = new ParallaxSwiper('.test', {
        target: '.inner',
        touch: true,
        listen: ({ animateLock, activeIndex }) => {
            if (animateLock) {
                $('.category-list li').eq(activeIndex).addClass('active').siblings().removeClass('active');
            }
            gsap.set($('.text'), { yPercent: 100, opacity: 0 });
        },
        callback: ({ animateLock, activeIndex }) => {
            gsap.to($('.test li').eq(activeIndex).find('.text'), { yPercent: 0, scale: 2, opacity: 1 });
        },
    });

    $('.next').on('click', function () {
        parallax.slideToNext();
    });

    $('.prev').on('click', function () {
        parallax.slideToPrev();
    });

    $('.category-list').on('click', 'li', function () {
        parallax.slideTo($(this).index());
    });


    // end
});




/*-------------------------------------------------------------------------------
## artis2
-------------------------------------------------------------------------------*/

function calcHeightLine() {
    if (document.querySelector('.cont-time-line')) {
        if (window.matchMedia("(max-width: 567px)").matches) {
            let lastIndice = document.querySelectorAll('.js-date-timeline').length - 1
            let lastEl = document.querySelectorAll('.js-date-timeline')[lastIndice]
            let resta = document.querySelector('.cont-time-line .position-relative').offsetHeight - lastEl.offsetHeight
            document.querySelector(".cont-time-line .line").style.height = (resta + 40) + "px";
        } else {
            let lastIndice = document.querySelectorAll('.js-date-timeline').length - 1
            let lastEl = document.querySelectorAll('.js-date-timeline')[lastIndice]
            let resta = document.querySelector('.cont-time-line .position-relative').offsetHeight - lastEl.offsetHeight
            document.querySelector(".cont-time-line .line").style.height = (resta - 60) + "px";
        }
    }
}

if (document.querySelector(".cont-time-line .line")) {
    calcHeightLine();

    const dateElements = document.querySelectorAll(".js-date-timeline");
    if (dateElements.length) {
        ScrollTrigger.matchMedia({
            "(min-width: 576px)": function () {
                dateElements.forEach((dateElement, i) => {
                    /// POINT ///
                    gsap.from(dateElement.querySelector('.point'), {
                        duration: 0.2,
                        scale: 1.5,
                        backgroundColor: " #C4C4C466",
                        ease: 'power3.out',
                        scrollTrigger: {
                            trigger: dateElement.querySelector('.point'),
                            start: "top 95%",
                        }
                    })
                    if (i !== dateElements.length - 1) {
                        dateElement.querySelectorAll('.info p').forEach((p) => {
                            gsap.fromTo(p, {
                                opacity: 0,
                                y: 50,
                            }, {
                                opacity: 1,
                                y: 0,
                                scrollTrigger: {
                                    trigger: dateElement.querySelector('.info'),
                                    start: " 60% top",
                                    ease: "power1.out",
                                }
                            })
                        })
                    }

                    /// INFOS ///
                    if (i === dateElements.length - 1) {
                        gsap.fromTo(dateElement.querySelector('.info'), {
                            opacity: 0,
                            y: 100,
                        }, {
                            opacity: 1,
                            y: 0,
                            scrollTrigger: {
                                trigger: dateElement.querySelector('.info'),
                                start: " 80% top",
                                ease: "power1.out",
                            }
                        })
                    }
                    if (dateElement.querySelector('.img')) {
                        gsap.fromTo(dateElement.querySelector('.img'), {
                            yPercent: 50,

                        }, {
                            yPercent: -100,
                            scrollTrigger: {
                                trigger: dateElement.querySelector('.cont-date'),
                                start: "top bottom",
                                end: "bottom top",
                                // markers: true,
                                toggleActions: "play reverse play reverse",
                                scrub: 3,
                                ease: "power2.out"
                            }
                        })
                    }
                })
            },
            "(min-width: 980px)": function () {
                dateElements.forEach((dateElement, i) => {

                    if (dateElement.classList.contains('right')) {
                        gsap.fromTo(dateElement.querySelector('.info'), {
                            opacity: 0,
                            x: 50,
                        }, {
                            opacity: 1,
                            x: 0,
                            scrollTrigger: {
                                trigger: dateElement.querySelector('.info'),
                                start: " 80% top",
                                ease: "power1.out",
                            }
                        })
                    }
                    if (dateElement.classList.contains('left')) {
                        gsap.fromTo(dateElement.querySelector('.info'), {
                            opacity: 0,
                            x: -50,
                        }, {
                            opacity: 1,
                            x: 0,
                            scrollTrigger: {
                                trigger: dateElement.querySelector('.info'),
                                start: " 80% top",
                                ease: "power1.out",
                            }
                        })
                    }
                })
            }
        })
    }
}

if (document.querySelector('.cont-client')) {
    const clients = document.querySelectorAll('.clients');
    const maxClientWidth = Math.max(...Array.from(clients).map(c => c.scrollWidth + 20));
    clients.forEach((c, index) => {
        const clientWidth = c.scrollWidth + 20;
        const clonedFigures = c.innerHTML + c.innerHTML;
        var tl = new TimelineLite({ paused: true, onComplete: resetAnimation });

        if (index % 2 !== 0) {
            c.style.flexDirection = 'row-reverse';
        }

        const speedFactor = clientWidth / maxClientWidth;
        tl.set(c, { x: 0 });

        tl.to(c, 60 * speedFactor, { x: (index % 2 === 0) ? -clientWidth : clientWidth, ease: Linear.easeNone });

        function resetAnimation() {
            tl.restart();
        }

        c.innerHTML = clonedFigures;

        gsap.fromTo(
            c.querySelectorAll('figure'),
            {
                y: 80,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                duration: 0.5,
                stagger: 0.1,
                delay: index * 1,
                scrollTrigger: {
                    trigger: c.parentElement,
                    start: 'top 70%',
                    ease: 'power1.out',
                },

            }
        );
        setTimeout(() => tl.play(), 3000);
    });
}
window.addEventListener("resize", calcHeightLine);

if (document.querySelector('.c-post__wrapper')) {
    document.querySelectorAll('.c-post__wrapper').forEach((el) => {
        if (window.innerWidth > 740) {
            if (el.querySelector('.info')) { }
            let alturaTotal = el.querySelector('.info').offsetHeight + el.querySelector('.text-bottom').offsetHeight + 32 + 16;
            let alturaWrapper = el.offsetHeight - alturaTotal;
            gsap.set(el, { height: 'auto', maxHeight: '${alturaTotal}' })
            gsap.set(el.querySelector('.c-post__content'), { marginTop: `0`, backgroundColor: 'transparent', height: 'auto', paddingTop: '24px', })
            gsap.set(el.querySelector('.c-post__title'), { paddingBottom: '24px' })
            gsap.set(el.querySelector('.hide-info'), { y: 0, height: 0, })
            gsap.set(el.querySelector('.info'), { autoAlpha: 0, })
            gsap.set(el.querySelector('.text-bottom'), { y: `100%` })

            let hover = gsap.timeline({ paused: true });

            hover.to(el, { height: `${alturaWrapper}`, duration: 0 })
            hover.to(el.querySelector('.c-post__content'), { backgroundColor: 'rgba(246, 246, 246, 0.70)', duration: 0.4, height: `${alturaTotal + 197 + 64}`, paddingTop: '197px', marginTop: `-=${alturaTotal + (197 - 10)}px` }, '<')
            hover.to(el.querySelector('.c-post__img'), { scale: 1.2, duration: 0.5 }, '<')
            hover.to(el.querySelector('.c-post__title'), { paddingBottom: '0', duration: 0.5 }, '<')
            hover.to(el.querySelector('.hide-info'), { transformOrigin: 'bottom', height: `${alturaTotal}`, duration: 0.5 }, '<')
            hover.to(el.querySelector('.info'), { autoAlpha: 1, duration: 0.8 }, '-=0.2')
            hover.to(el.querySelector('.text-bottom'), { y: `0`, duration: 0.3 }, '-=0.8')

            el.addEventListener('mouseleave', () => {
                hover.reverse()
            })
            el.addEventListener('mouseenter', () => {
                hover.play()
            })
        }
    })
}

if (document.querySelector('.swiper-panel')) {
    let anim = gsap.timeline();

    document.querySelectorAll('.swiper-panel').forEach((swiper) => {
        let imgs = swiper.querySelectorAll(".imgs figure");
        let infos = swiper.querySelectorAll('.info-swiper')

        let currentIndex = -1;
        let animating;
        let animatingCompleted = true;
        let swipePanels = gsap.utils.toArray(imgs);
        gsap.set(imgs, { xPercent: 100 });
        gsap.set(infos, { autoAlpha: 0 });

        gsap.set(swipePanels, { zIndex: i => i });
        ////////// ESTO ES PARA MOVILES SOBRETODO VER COMO FUNCIONA LUEGO ///////////////
        let intentObserver = ScrollTrigger.observe({
            type: "wheel,touch",
            onUp: () => !animating && gotoPanel(currentIndex + 1, true),
            onDown: () => !animating && gotoPanel(currentIndex - 1, false),
            wheelSpeed: -1,
            tolerance: 10,
            preventDefault: true,
            onPress: self => {
                ScrollTrigger.isTouch && self.event.preventDefault()
            }
        })
        intentObserver.disable();

        let preventScroll = ScrollTrigger.observe({
            preventDefault: true,
            type: "wheel,scroll",
            allowClicks: true,
            onEnable: self => self.savedScroll = self.scrollY(),
            onChangeY: self => self.scrollY(self.savedScroll)
        });
        preventScroll.disable();

        ////////// AQUI ESTA CASI TODO ES LA FUNCION PRINCIPAL//////
        function gotoPanel(index, isScrollingDown) {
            // console.log('index', index, animatingCompleted)

            if ((index === swipePanels.length && isScrollingDown) || (index === -1 && !isScrollingDown)) {
                intentObserver.disable();
                preventScroll.disable();
                preventScroll.scrollY(preventScroll.scrollY() + (index === swipePanels.length ? 1 : -1));
                return;
            }
            let target = isScrollingDown ? swipePanels[index] : swipePanels[currentIndex];
            // // // // // // ESTO ES LA ANIMACION DE LAS IMAGENES
            if (animatingCompleted) {
                animatingCompleted = false
                if (index === 0 && isScrollingDown) {
                    anim
                        .to(target, { xPercent: 0, duration: 0.1 })
                        .fromTo(target.querySelector('.c-herob__curtain'), {
                            xPercent: 100,
                        }, {
                            xPercent: 0,
                            ease: "expo.inOut",
                            duration: 1.1,
                        })
                        .fromTo(target.querySelector('img'), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 1.1)
                        .to(target.querySelector('.c-herob__curtain'), {
                            xPercent: -100,
                            ease: "expo.inOut",
                            duration: 1.1
                        }, 1.4)

                } else {
                    anim.to(target, {
                        duration: 0.75,
                        xPercent: isScrollingDown ? 0 : 100,
                        onStart: () => {
                            animatingCompleted = false;
                        }
                    });
                }
                // // // // // // // FIN ANIMACION IMAGENES

                infos.forEach((info) => {
                    ///////////// ESTO HACE QUE SE ELIMINEN LOS ELEMENTOS ANTES DE QUE PASE AL SIGUIENTE SLIDE ///////////////
                    if (index !== -1 && index !== 3 && currentIndex !== -1) {
                        if (index !== currentIndex) {
                            // console.log('lo que hace que elimenen los items index',index,'currentindex', currentIndex)
                            var tl = gsap.timeline();
                            tl
                                .to(info.querySelector('.c-herob__curtain'), { xPercent: 0, transformOrigin: 'left', duration: 0.4 }, '<')
                                .to(info.querySelector('figure img'), { opacity: 0, duration: 0.1 })
                                .to(info.querySelector('.c-herob__curtain'), { xPercent: 100, transformOrigin: 'left', duration: 0.3 })
                                .to(info.querySelectorAll('.text'), { yPercent: 101, stragger: 0.3, duration: 0.3 }, '-=0.4')
                                .to(info.querySelector('.btn'), { yPercent: 101, duration: 0.3 }, '<')
                                .to(info.querySelector('.logo'), { yPercent: 100, duration: 0.3 }, '<')
                                .to(info.querySelector('.layer-square'), { scaleX: 0, transformOrigin: 'left', duration: 0.3 }, '-=0.1')
                        }
                    }
                    ///////////// FIN HACE QUE SE ELIMINEN LOS ELEMENTOS ANTES DE QUE PASE AL SIGUIENTE SLIDE ///////////////

                    if (info.getAttribute("data-position") === index.toString()) {
                        anim
                            .to(info, { autoAlpha: 1, duration: 0.01 }, '-=0.6')
                        if (index === 0 && isScrollingDown) {
                            // console.log('holi')
                            anim.fromTo(info.querySelector('.c-herob__curtain'), {
                                xPercent: 100
                            }, {
                                xPercent: 0,
                                ease: "expo.inOut",
                                duration: 1.1
                            }, 1.4)
                                .fromTo(info.querySelector('figure img'), {
                                    autoAlpha: 0
                                }, {
                                    autoAlpha: 1, duration: 0.1
                                }, 2.2)
                                .to(info.querySelector('.c-herob__curtain'), {
                                    xPercent: -100,
                                    ease: "expo.inOut",
                                    duration: 1.1
                                }, 2.2)
                        } else {
                            anim.to(info.querySelector('.c-herob__curtain'), {
                                xPercent: 0,
                                ease: "expo.inOut",
                                duration: 1.1
                            },)
                                .fromTo(info.querySelector('figure img'), {
                                    autoAlpha: 0
                                }, {
                                    autoAlpha: 1, duration: 0.01
                                })
                                .to(info.querySelector('.c-herob__curtain'), {
                                    xPercent: -100,
                                    ease: "expo.inOut",
                                    duration: 1.1
                                }, '-=0.1')
                        }
                        anim
                            .fromTo(info.querySelector('figure img'), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 1.1)
                            .to(info.querySelector('.c-herob__curtain'), {
                                xPercent: -100,
                                ease: "expo.inOut",
                                duration: 1.1
                            }, 1.1)
                        if (index === 0 && isScrollingDown) {
                            anim
                                .fromTo(info.querySelector('.triangle'), { yPercent: 100, transformOrigin: 'bottom' }, { yPercent: 0, ease: "expo.inOut", duration: 1.1 }, '-=1.5')
                                .fromTo(info.querySelector('.cont-triangle-cont'), { y: -10, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: "expo.inOut", duration: 0.5 }, '-=0.3')
                        }
                        anim
                            .fromTo(info.querySelector('.layer-square'), { scaleX: 0, transformOrigin: 'left' }, { scaleX: 1, ease: "expo.inOut", duration: 0.8 }, '-=1.4')
                            .to(info.querySelectorAll('.text'), { yPercent: 0, duration: 0.01 }, '<')
                            .fromTo(info.querySelector('.logo'), { yPercent: 100 }, { yPercent: 0, duration: 0.5 }, '-=0.5')
                        let split = new SplitText(info.querySelectorAll('.text'), { type: "lines" });
                        anim.fromTo(split.lines, {
                            yPercent: 304,
                        }, {
                            yPercent: 0,
                            linesClass: "split-line",
                            ease: "circ.out",
                            stagger: 0.1,
                            duration: 0.5
                        }, '<')
                        anim.fromTo(info.querySelector('.btn'), { yPercent: 101 }, {
                            yPercent: 0, duration: 0.5,
                            onComplete: () => {
                                // console.log('se termina aquí, iría el true?', animatingCompleted);
                                animatingCompleted = true
                            }
                        }, '<')
                    }
                })
                changeSlide(index)
                currentIndex = index;
                // console.log(index)
            }

        }
        let st = ScrollTrigger.create({
            trigger: swiper,
            pin: true,
            start: "top top",
            end: "+=50%",
            // markers: true,
            onEnter: (self) => {
                if (preventScroll.isEnabled === false) {
                    self.scroll(self.start);
                    preventScroll.enable();
                    intentObserver.enable();
                    // console.log('currentIndex', currentIndex)
                    gotoPanel(currentIndex + 1, true);
                }
            },
            onEnterBack: (self) => {
                if (preventScroll.isEnabled === false) {
                    self.scroll(self.start);
                    preventScroll.enable();
                    intentObserver.enable();
                    gotoPanel(currentIndex - 1, false);
                }
            },
        });

        swiperPanel = new Swiper(".nav-swiper-panel", {
            slidesPerView: "auto",
            slideToClickedSlide: true,
            on: {
                click() {
                    if (this.activeIndex < this.clickedIndex) {
                        gotoPanel(this.clickedIndex, true, true)
                    } else {
                        gotoPanel(this.clickedIndex, false, true)
                    }
                    changeSlide(this.clickedIndex)
                }
            }
        });
    })

    // MEJORAR Y MAS ADELANTE VER PORQUE NO VA EL SLIDETO
    function changeSlide(index) {
        swiperPanel.slides.forEach((el, i) => {
            el.classList.remove('swiper-slide-active', 'swiper-slide-next', 'swiper-slide-prev');
            if (index == i) {
                if (i == 0) {
                    el.nextElementSibling.classList.add('swiper-slide-next')
                } else if (i === swiperPanel.slides.length - 1) {
                    el.previousElementSibling.classList.add('swiper-slide-prev')
                } else {
                    el.previousElementSibling.classList.add('swiper-slide-prev')
                    el.nextElementSibling.classList.add('swiper-slide-next')
                }
                el.classList.add('swiper-slide-active')
            }
        })

    }
}
